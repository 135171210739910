export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'full_name', label: "Nama Lengkap", _style:'min-width:100px' },
    { key: 'school_name', label: "Nama Sekolah", _style:'min-width:100px' },
    { key: 'user_id', label: "User", _style:'min-width:100px' },
    { key: 'payment_id', label: "Payment", _style:'min-width:100px' },
    { key: 'test_group_id', label: "Test Group", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
